@import "/src/styles/variables.scss";

.wrap {
  width: 100%;
}

.main {
  //width: fit-content;
}


.tableContainer {
  .tableWrap {
    border-radius: 3px;
    border: 1px solid $color-black-10;
   //width: fit-content;

    .table {

      .smallPad {
        padding-left: 6px !important;
        padding-right: 6px !important;
      }

      &.compact {
        :global(.MuiTableCell-body) {
          padding: 3px 12px 3px 16px;
        }

        :global(.MuiTableCell-head) {
          padding-left: 12px;
          padding-right: 16px;
        }

        .textContainer {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      thead {

        th:not(:last-child) {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            right: 0;
            top: Calc(50% - 10px);
            width: 2px;
            background: $color-black-10;
            height: 20px;
          }
        }
      }

      tbody {
      }

      tbody tr:nth-child(odd):not(.selected) {
        background-color: $color-black-10;
      }

      tbody tr:nth-child(even):not(.selected) {
        background-color: $color-black-03;
      }

      tbody tr:hover {
        background-color: $color-blue-15 !important;

      }

      tbody tr.selected {
        //background-color: $color-primary-05;
      }

      .selected {
        background-color: $color-blue-10;
      }

      .subTable {

        tbody tr:nth-child(even):not(.selected) {
          background-color: transparent;
        }

        :global {
          .MuiTableCell-head {
            padding: 3px 6px 0 8px;
            border-bottom: none;
          }

          .MuiTableCell-body {
            padding: 0 9px 0 5px;
          }

          .MuiTableCell-root {
            border-bottom: none;
          }

          tr:hover {
            background-color: transparent !important;
          }
        }

        .subTableName {
          border-bottom: 2px solid $color-black-10;
          text-align: center;
        }

      }

      .buttonSelect {
        opacity: 1;
      }

    }
  }

}
