@import "/src/styles/variables.scss";

.main {
  position: fixed;
  background: $color-background;
  height: $heightHeader;
  width: 100%;
  padding: 0 15px 0 15px;
  color: $color-white-75;

  .logo{
    .img {
      padding: 0 10px 0 0;
      img {
        max-width: 30px;
        height: fit-content;
      }
    }
    .text{
      padding: 0 10px 0 0;
      opacity: 0.85;
    }

    &:hover{
      .text{
        opacity: 1;
      }
    }
  }
}

.menuItem {
  height: 100%;
  position: relative;

  &:hover {
    .subMenu {
      visibility: visible;
      opacity: 1;
    }
  }

  .link {
    height: 100%;
    padding: 7px;
    margin: 0 0 0 10px;

    &.selected {
      box-shadow: 0 -3px $color-highlight-light inset;
      background: $color-highlight-light-15;
    }

    .text {
      white-space: nowrap;

      &:hover {
        color: $color-white-85;
      }
    }

    .iconImg {
      height: 100%;

      span {
        font-size: 28px;
      }

      &:hover {
        color: $color-white-85;
      }
    }
  }

  .iconAdd {
    height: 100%;
    margin-left: 7px;

    span {
      font-size: 21px;
      color: $color-white-60;
    }

    &:hover span{
      color: $color-white-85;
    }
  }

  .subMenu {
    position: absolute;
    top: 100%;
    left: 0;
    background: $color-background;
    border-radius: 0 0 3px 3px;
    padding: 5px 0;
    width: auto;
    //min-width: 170px;

    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;

    .subMenuItem {
    }

    .link2 {
      min-height: 35px;
      padding: 7px 40px 7px 10px;
      margin: 0 0 0 3px;

      .textSubMenu {
        white-space: nowrap;
        position: relative;

        &:hover {
          color: $color-white-85;
        }
      }

      &.selected {
        box-shadow: 3px 0 $color-highlight inset;
      }

      &.selectedSubMenu {
        .textSubMenu:before{
          background: $color-highlight;
          height: 29px;
          width: 3px;
          position: absolute;
          left: -8px;
          top: -5px;
          content: "";
        }
      }

    }
  }

  .subMenuList {
    margin: 0;
    padding: 0 0 0 20px;
    margin-left: 17px;


    .subMenuListItem {
      .link3 {
        min-height: 35px;
        position: relative;

        &:before {
          position: absolute;
          border-radius: 5px;
          height: 8px;
          width: 8px;
          background: $color-white-80;
          content: '';
          left: -20px;
          top: calc(50% - 5px);
        }

        &.selected {
          &:before {
            background: $color-highlight;
          }
        }
      }
    }
  }
}

.rightWrap{
  padding: 0 10px;

  a{
    opacity: 0.6;
    &:hover{
      opacity: 1;
    }
  }
}

.userWrap{

  .iconImg{
    cursor: pointer;
    &:hover{
      color: $color-white-90;
    }
  }

  .userName{
    max-width: 180px;
    padding: 0 10px;
  }

  .exit{
    padding-left: 10px;
  }
}

.balanceWrap{
  margin: 10px 10px;
  padding: 7px 10px;
  background: $color-white-05;
  border-radius: 10px;

  &.danger{
    border: 1px solid $color-yellow;
  }

  .text{
    opacity: 0.6;
  }

  .value{
    padding-left: 7px;
    opacity: 0.85;
  }

  .divider{
    padding: 0 5px;
    opacity: 0.6;
  }
}

.lang{
  padding: 10px 0 10px 10px;
}