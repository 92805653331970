$color-secondary: white;
$color-primary: #142666;
$color-primary-05: rgba(20, 38, 102, 0.05);
$color-primary-light: #6C82D4;

$color-highlight: rgb(74, 109, 236);
$color-highlight-75: rgb(74, 109, 236, 0.75);
$color-highlight-light: rgb(95, 121, 211);
$color-highlight-light-15: rgb(95, 121, 211, 0.15);
//$color-primary-85: rgba(253, 216, 53, 0.85);
//$color-primary-45: rgba(253, 216, 53, 0.45);
//$color-primary-15: rgba(253, 216, 53, 0.15);
$color-text: rgba(0, 0, 0, 0.85);
$color-background: rgb(48, 48, 48);

$color-white: #FFF;
$color-white-95: rgba(255, 255, 255, 0.95);
$color-white-90: rgba(255, 255, 255, 0.9);
$color-white-85: rgba(255, 255, 255, 0.85);
$color-white-80: rgba(255, 255, 255, 0.8);
$color-white-75: rgba(255, 255, 255, 0.75);
$color-white-70: rgba(255, 255, 255, 0.70);
$color-white-65: rgba(255, 255, 255, 0.65);
$color-white-60: rgba(255, 255, 255, 0.60);
$color-white-40: rgba(255, 255, 255, 0.40);
$color-white-30: rgba(255, 255, 255, 0.30);
$color-white-25: rgba(255, 255, 255, 0.25);
$color-white-20: rgba(255, 255, 255, 0.20);
$color-white-15: rgba(255, 255, 255, 0.15);
$color-white-10: rgba(255, 255, 255, 0.10);
$color-white-05: rgba(255, 255, 255, 0.05);
$color-white-03: rgba(255, 255, 255, 0.03);

$color-black: rgba(0, 0, 0, 1);
$color-black-90: rgba(0, 0, 0, 0.90);
$color-black-85: rgba(0, 0, 0, 0.85);
$color-black-80: rgba(0, 0, 0, 0.80);
$color-black-75: rgba(0, 0, 0, 0.75);
$color-black-70: rgba(0, 0, 0, 0.75);
$color-black-65: rgba(0, 0, 0, 0.65);
$color-black-60: rgba(0, 0, 0, 0.60);
$color-black-40: rgba(0, 0, 0, 0.40);
$color-black-30: rgba(0, 0, 0, 0.30);
$color-black-20: rgba(0, 0, 0, 0.20);
$color-black-15: rgba(0, 0, 0, 0.15);
$color-black-10: rgba(0, 0, 0, 0.10);
$color-black-07: rgba(0, 0, 0, 0.07);
$color-black-05: rgba(0, 0, 0, 0.05);
$color-black-03: rgba(0, 0, 0, 0.03);
$color-black-02: rgba(0, 0, 0, 0.02);
$color-black-01: rgba(0, 0, 0, 0.01);

$color-blue: #2129a7;
$color-blue-40: rgba(33, 41, 167, 0.4);
$color-blue-30: rgba(33, 41, 167, 0.3);
$color-blue-20: rgba(33, 41, 167, 0.2);
$color-blue-15: rgba(33, 41, 167, 0.15);
$color-blue-10: rgba(33, 41, 167, 0.1);


$color-gray: #f1f1f1;
$color-gray-white: #fafafa;
$color-gray-dark: #eeeeee;

$color-green: #53a30e;
$color-green-90: rgba(83, 163, 14, 0.9);
$color-green-80: rgba(83, 163, 14, 0.8);
$color-green-60: rgba(83, 163, 14, 0.6);
$color-green-30: rgba(83, 163, 14, 0.3);
$color-green-20: rgba(83, 163, 14, 0.2);
$color-green-10: rgba(83, 163, 14, 0.1);

$color-red: #CA2C2C;
$color-red-90: rgba(202, 44, 44, 0.9);
$color-red-60: rgba(202, 44, 44, 0.6);
$color-red-30: rgba(202, 44, 44, 0.3);
$color-red-10: rgba(202, 44, 44, 0.1);

$color-yellow: #e7bf34;
$color-yellow-60: rgba(231, 191, 52, 0.6);
$color-yellow-40: rgba(231, 191, 52, 0.4);
$color-yellow-30: rgba(231, 191, 52, 0.3);
$color-yellow-dark: #c9a62e;

$minWidth: 1000px;
$heightHeader: 54px;
$heightHeaderMobile: 56px;
