@import "/src/styles/variables.scss";

.main {
  width: 100vw;
  height: 100vh;
}

.topPanel {
  padding: 0 10px;

  .title {
    font-size: larger;
    padding: 0 20px 0 10px;
  }

  .menu {
    font-size: large;
    padding: 0 20px;
  }

  .icon, .title, .menu {
    opacity: 0.85;
  }

  .tokensLeft {
    padding: 10px;

    a {
      background: $color-white-05;
      border-radius: 10px;
      opacity: 0.6;
      display: block;

      .comment {
        opacity: 0.6;
      }
    }
  }
}

.content {
  overflow: auto;
  max-width: 100%;
  position: relative;
  color: $color-black-75;

  .chat {
    overflow: auto;

    .messagesEnd {
      margin-top: 80px;
    }

    .messageHuman, .messageAI {
      width: fit-content;
      max-width: 80vw;
      border-radius: 3px;
      margin: 15px 20px;
      overflow: hidden;
    }

    .messageHuman .text, .messageAI .text {
      padding: 15px 20px;
    }

    .messageHuman {
      background: $color-blue-10;
    }

    .message {
      /* &:hover {
         .tokensUse {
           visibility: visible;
         }
       }*/
    }

    .messageAI {
      //background: $color-black-05;
      margin-top: 0;

      .tokensUse {
        padding: 5px 10px;
        opacity: 0.45;
        font-size: small;
        //visibility: hidden;
      }

      .yes, .no {
        padding: 5px 20px;
        cursor: pointer;
      }

      .text {
        background: $color-black-03;
        white-space: pre-wrap;
      }

      .command {
        background: $color-black-03;
        margin: 0 0 0 0;

        .inner {
          padding: 10px 20px;

          .title {
            font-size: small;
            opacity: 0.65;
            padding: 0 0 10px 0;
          }

          .data {
            white-space: pre-wrap;
          }
        }

        .process {
          padding: 5px 20px;
          background: $color-yellow-40;
          color: $color-white-85;
          margin: 0 0 0 20px;
          cursor: default;
        }

        .refine {
          padding: 5px 20px;
          background: $color-highlight-75;
          color: $color-white-85;
          cursor: pointer;

          &:hover, &.selected {
            background: $color-highlight;
          }
        }

        .yes {
          background: $color-green-30;
          color: $color-white-85;
          margin: 0 0 0 20px;

          &:hover, &.selected {
            background: $color-green-90;
          }
        }

        .no {
          background: $color-red-30;
          color: $color-white-85;

          &:hover, &.selected {
            background: $color-red-90;
          }
        }
      }

      .result {
        border-radius: 3px;

        .title {
          opacity: 0.85;
          padding: 10px 10px 10px 20px;
          background: $color-green-30;

          span {
            opacity: 0.65;
          }
        }

        .details {
          padding: 20px;
          background: $color-green-20;
          white-space: pre-wrap;
        }
      }

      .error {
        border-radius: 3px;

        .title {
          opacity: 0.85;
          padding: 10px 10px 10px 20px;
          background: $color-red-30;

          span {
            opacity: 0.65;
          }
        }

        .continue {
          background: $color-green-60;
          padding: 10px 20px;
          border-radius: 0;

          &:hover {
            background: $color-green-80;
          }
        }

        .details {
          padding: 20px;
          background: $color-red-10;
        }
      }
    }
  }

  .control {
    background: $color-black-05;

    .controlInput {
      margin: 5px 10px 10px 20px;
    }

    .controlClear {
      margin: 0 0 0 10px;
    }

    .controlSend {

    }
  }

  .settings {
    padding: 20px 40px;

    h1, h2, h3 {
      font-weight: normal;
    }

    h1 {
      margin: 20px 0 40px 0;
    }

    .price {
      opacity: 0.6;
      margin: 0 0 0 20px;
    }
  }
}

:global(.desk) {


}

:global(.mob) {
  .topPanel {
    padding: 0;

    .title {
      padding: 0;
      width: 100%;
    }

    .tokensLeft {
      width: 100%;
    }

    .menu {
      padding: 0;
    }
  }

  .settings {
    padding: 15px 15px;

    h1 {
      margin: 10px 0 20px 0;
    }

    .price {
      display: block;
      padding: 10px 0 0 0;
      margin: 0;
    }
  }

  .control {

    &:has(.controlClear) {
      .controlInput {
        margin-left: 0;
      }
    }

    .controlInput {
      margin-left: 10px;
      margin-right: 0;
    }

    .controlClear {
      margin: 0 0 0 0;
    }
  }
}
