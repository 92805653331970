@import "/src/styles/variables.scss";

.modalWrap {
  height: 100%;
}

.modal{
  //max-width: 80%;
  //height: 100%;
  overflow: auto;
}