@import "/src/styles/variables.scss";

.main {
  //position: fixed;
  //bottom: 0;
  //height: $heightHeaderMobile;
  width: 100%;
  color: $color-white-75;
}

.navigation {
  background: $color-background;
  width: 100%;
  position: fixed;
  bottom: 0;

  :global(.MuiBottomNavigationAction-root) {
    color: $color-white-60;
  }

  :global(.MuiBottomNavigationAction-root.Mui-selected) {
    color: $color-white-85;
    box-shadow: 0px -4px 0px -1px $color-white-85;
  }
}

.menu {
  top: 100%;
  visibility: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: $color-background;
  transition: all 150ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
  font-size: 1.2em;
  font-weight: 400;
  padding: 0px 0 100px 0;
  color: $color-white-70;
  overflow: auto;

  .logo {
    padding: 20px 20px;
    //background: $color-black-10;
    width: 100%;

    .img {
      padding: 0 10px 0 0;

      img {
        max-width: 32px;
        height: fit-content;
      }
    }

    .text {
      padding: 0 10px 0 0;
      font-size: 1.4em;
    }
  }

}

.menuOpen {
  top: 0;
  visibility: visible;
}

.menuItem {
  padding: 7px 25px;
  overflow: auto;

  &:hover {
    background: $color-white-10;
  }
}

.listMenu {
  .item {
    .circle {
      background: $color-white-10;
      border-radius: 50%;
      padding: 0.30em;
      margin-right: 10px;
    }

    .navLink {
      padding: 10px 25px;

      &:hover {
        background: $color-white-10;
      }

      &.activeParent {
        position: relative;
        border-left: 3px solid $color-highlight-light;

        /*&:before{
          background: $color-highlight;
          //height: 29px;
          width: 3px;
          position: absolute;
          left: 0px;
          top: 0;
          bottom: 0;
          content: "";
        }*/
      }

      .activeCircle {
        position: relative;

        .circle {
          background: $color-highlight;
        }
      }
    }

    .listMenuSub {
      padding: 7px 0 7px 20px;
    }
  }
}

.topWrap {
  padding: 5px 0;
  text-align: right;

  a {
    opacity: 0.85;
    padding: 10px 20px;

    &:hover:active {
      opacity: 1;
    }
  }
}

.userWrap {
  padding: 0 10px;
  margin-bottom: 10px;

  .menuItem {
    padding: 0 10px;

    &:hover {
      background: transparent;
    }

    .userName {
      padding: 10px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: center;

      &.selected {
        border-bottom: 3px solid $color-highlight-light;
      }
    }

  }
}

.userStats {
  color: $color-white-40;

  .userStateName {
    padding: 0 10px;
  }
}


.subMenu {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: $color-background;
  transition: all 150ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
  font-size: 1.2em;
  font-weight: 400;
  padding: 10px 0 74px 0;
  color: $color-white-70;
  overflow: auto;
}

.subMenuOpen {
  visibility: visible;
}


.balanceWrap {
  margin: 10px 10px;
  padding: 7px 10px;
  background: $color-white-05;
  border-radius: 10px;

  &.danger {
    border: 1px solid $color-yellow;
  }

  .text {
    opacity: 0.6;
  }

  .value {
    padding-left: 7px;
    opacity: 0.85;
  }

  .divider {
    padding: 0 5px;
    opacity: 0.6;
  }
}


.lang {
  padding: 0 10px;
  margin: 0 10px;
  z-index: 100;
  color: $color-white-60;

  background: $color-white-05;
  border-radius: 10px;

  .list{
    left: -200%;
  }
}