@import "/src/styles/variables.scss";

.main{
  //padding: 4px 10px 4px 6px !important;
}

.icon{
  opacity: 0.75;
}

.changeDirWrap{

  .icon{
    opacity: 0.35;
    margin-right: 5px;

    &:hover{
      cursor: pointer;
      background: $color-black-05;
    }

    &.selected{
      opacity: 0.95;
      background: $color-black-05;
    }

  }
}
.itemMenu{

}