@import "/src/styles/variables.scss";

.main{
  //padding: 4px 10px 4px 6px !important;
  text-transform: none;
  font-size: 1em;
  font-weight: normal;
  /*&.active{
    background: $color-yellow-30;
    &:hover{
      background: $color-yellow-40;
    }
  }
  &:hover{
    background: $color-black-05;
  }*/
}

.icon{
  opacity: 0.55;
  font-size: 22px;
  &:hover{
    opacity: 1;
  }
}

.text{
  padding: 0 10px 0 0;
  opacity: 0.6;
}

.inputWrap{
  padding-top: 0;
}

.inputAutocomplete{
  min-width: 200px;
}

.inputDropdown{
  min-width: 200px;
}

.addText{
  margin-right: 5px;
  opacity: 0.6;
}

.errors{
  padding: 5px 0;
  color: $color-red-90;
}

.checkbox{

  span{
    cursor: pointer;
    vertical-align: middle;
  }
}
