@import "/src/styles/variables.scss";

.main{

  &:has(.icon) {
    padding-left: 10px;
  }

  &.noChildren{
    padding-right: 10px;

    .icon{
      opacity: 0.55;
      font-size: 18px;
      padding-right: 0;
    }
  }

  &:hover{
    .icon{
      opacity: 1;
    }
  }

  .icon{
    opacity: 0.55;
    font-size: 18px;
    padding-right: 5px;


  }

  :global(.MuiButton-label){
    vertical-align: center;
  }
}