@import "../variables";

//MATERIAL

.MuiButton-root:not(.bigButton) {
  padding: 4px 16px;
}

.MuiButton-root:not(.MuiButton-containedPrimary):not(.MuiButton-containedSecondary)  {
  color: $color-black-85;
  //text-transform: none;
}

.MuiButton-contained:not(.MuiButton-containedPrimary):not(.MuiButton-containedSecondary) {
  color: $color-black-85;
  background-color: $color-black-05;
  &:hover{
    background-color: $color-black-10;
  }
}

.MuiButton-root.MuiButton-containedSecondary  {
  color: $color-white-85;
}

.MuiChip-root{
  font-size: 1em;
  background: #0000000d;
  //margin-top: 0px;
  padding: 3px;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  min-width: 80px;
}

/*
.MuiOutlinedInput-root:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline {
  border-color: $color-highlight !important;
  border-width: 2px;
}

.MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
  border-color: $color-highlight-75;
  border-width: 2px;
}*/

.noColor{
  &.Mui-checked{
    color: $color-black-60;
  }
}

.colorCheckboxRed{
  color: $color-red-90;
  &.Mui-checked{
    color: $color-red;
  }
}

.MuiTab-root.Mui-selected{
  border-bottom: 3px solid $color-highlight;
}

.MuiButtonBase-root.Mui-disabled {
  opacity: 0.2;
}

.mob{
  .MuiDialogContent-root{
    padding: 8px 0;
  }
}