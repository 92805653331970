$cols: 24;
// @MIXIN
@mixin order($order) {
  order: $order;
}

@for $i from 0 to $cols {
  .or-#{$i} {
    @include order($i);
  }
}

@mixin z-index($z) {
  z-index: $z;
}

@for $i from 0 to $cols {
  .or-#{$i} {
    @include order($i);
  }
  .z-#{$i} {
    @include z-index($i);
  }
}

// DISPLAYS

.d-b {
  display: block;
}

.d-i-b {
  display: inline-block;
}

.d-f {
  display: flex;
}

.d-i-f {
  display: inline-flex;
}

.d-n {
  display: none;
}

// FLEX

.f-a {
  flex: auto;
}

// FLEX DIRECTIONS

.f-d {
  flex-direction: row;
}

.f-d-r {
  flex-direction: row-reverse;
}

.f-d-c {
  flex-direction: column;
}

.f-d-c-r {
  flex-direction: column-reverse;
}

.f-g {
  flex-grow: 1;
}

// JUSTIFY-CONTENTS

.j-c-c {
  justify-content: center;
}

.j-c-s-b {
  justify-content: space-between;
}

.j-c-s-a {
  justify-content: space-around;
}

.j-c-f-e {
  justify-content: flex-end;
}

.j-c-f-s {
  justify-content: flex-start;
}

.a-i-c {
  align-items: center;
}

.a-i-e {
  align-items: flex-end;
}

.a-i-s {
  align-items: flex-start;
}

.a-i-stretch {
  align-items: stretch;
}

// POSITION
.p-r {
  position: relative;
}

.p-a {
  position: absolute;
}

.p-f {
  position: fixed;
}

.p-i {
  position: inherit;
}

.f-l {
  float: left;
}

.f-r {
  float: right;
}

.o-h {
  overflow: hidden;
}

.o-a {
  overflow: auto;
}

.c-i {
  color: inherit !important;
}

.m-w-200 {
  max-width: 200px;
}

.b-s {
  border-radius: 5px;
}

.v-h {
  visibility: hidden;
}

.td-l {
  text-decoration: line-through;
}

.o-8 {
  opacity: .8;
}

.o-6 {
  opacity: .6;
}

.o-4 {
  opacity: .4;
}

.o-3 {
  opacity: .3;
}

.o-0 {
  opacity: .0;
}


.flexCenter, .f-c {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexCenterLeft, .f-c-l {
  @extend .flexCenter;
  justify-content: flex-start;
}

.flexCenterRight, .f-c-r {
  @extend .flexCenter;
  justify-content: flex-end;
}

.flexCenterColumn, .f-c-c {
  @extend .flexCenter;
  flex-direction: column;
}

.flexBottom, .f-b {
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
}

.flexCenterTop, .f-c-t {
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
}

.flexCenterBottom, .f-c-b {
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.noSelectL {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.click {
  @extend .noSelectL;
  cursor: pointer;
}

.drag {
  @extend .noSelectL;
  cursor: grab;
}

.c-d {
  cursor: default;
}


.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.dir-rev {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.dir-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dir-col-rev {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.col {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.col-fixed {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;

}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;

}

.col-1 {
  width: 8.3333%;
}

.col-2 {
  width: 16.6667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.3333%;
}

.col-5 {
  width: 41.6667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.3333%;
}

.col-8 {
  width: 66.6667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.3333%;
}

.col-11 {
  width: 91.6667%;
}

.col-12 {
  width: 100%;
}

.offset-12 {
  margin-left: 100%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-0 {
  margin-left: 0%;
}

.break {
  flex-basis: 100%;
  height: 0;
}


@media only screen and (max-width: 767px) {

  .mob-0 {
    display: none
  }

  .mob-1,
  .mob-2,
  .mob-3,
  .mob-4,
  .mob-5,
  .mob-6,
  .mob-7,
  .mob-8,
  .mob-9,
  .mob-10,
  .mob-11,
  .mob-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .mob-1 {
    width: 8.3333%;
  }

  .mob-2 {
    width: 16.6667%;
  }

  .mob-3 {
    width: 25%;
  }

  .mob-4 {
    width: 33.3333%;
  }

  .mob-5 {
    width: 41.6667%;
  }

  .mob-6 {
    width: 50%;
  }

  .mob-7 {
    width: 58.3333%;
  }

  .mob-8 {
    width: 66.6667%;
  }

  .mob-9 {
    width: 75%;
  }

  .mob-10 {
    width: 83.3333%;
  }

  .mob-11 {
    width: 91.6667%;
  }

  .mob-12 {
    width: 100%;
  }

  .mob-offset-12 {
    margin-left: 100%;
  }

  .mob-offset-11 {
    margin-left: 91.66666667%;
  }

  .mob-offset-10 {
    margin-left: 83.33333333%;
  }

  .mob-offset-9 {
    margin-left: 75%;
  }

  .mob-offset-8 {
    margin-left: 66.66666667%;
  }

  .mob-offset-7 {
    margin-left: 58.33333333%;
  }

  .mob-offset-6 {
    margin-left: 50%;
  }

  .mob-offset-5 {
    margin-left: 41.66666667%;
  }

  .mob-offset-4 {
    margin-left: 33.33333333%;
  }

  .mob-offset-3 {
    margin-left: 25%;
  }

  .mob-offset-2 {
    margin-left: 16.66666667%;
  }

  .mob-offset-1 {
    margin-left: 8.33333333%;
  }

  .mob-offset-0 {
    margin-left: 0%;
  }

  .w-mob-100 {
    width: 100%;
  }

  .mob-reverse {
    flex-direction: row-reverse;
  }

  .mob-fixed {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .mob-col {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {

  .big-mob-0 {
    display: none
  }

  .big-mob-1,
  .big-mob-2,
  .big-mob-3,
  .big-mob-4,
  .big-mob-5,
  .big-mob-6,
  .big-mob-7,
  .big-mob-8,
  .big-mob-9,
  .big-mob-10,
  .big-mob-11,
  .big-mob-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .big-mob-1 {
    width: 8.3333%;
  }

  .big-mob-2 {
    width: 16.6667%;
  }

  .big-mob-3 {
    width: 25%;
  }

  .big-mob-4 {
    width: 33.3333%;
  }

  .big-mob-5 {
    width: 41.6667%;
  }

  .big-mob-6 {
    width: 50%;
  }

  .big-mob-7 {
    width: 58.3333%;
  }

  .big-mob-8 {
    width: 66.6667%;
  }

  .big-mob-9 {
    width: 75%;
  }

  .big-mob-10 {
    width: 83.3333%;
  }

  .big-mob-11 {
    width: 91.6667%;
  }

  .big-mob-12 {
    width: 100%;
  }

  .big-mob-offset-12 {
    margin-left: 100%;
  }

  .big-mob-offset-11 {
    margin-left: 91.66666667%;
  }

  .big-mob-offset-10 {
    margin-left: 83.33333333%;
  }

  .big-mob-offset-9 {
    margin-left: 75%;
  }

  .big-mob-offset-8 {
    margin-left: 66.66666667%;
  }

  .big-mob-offset-7 {
    margin-left: 58.33333333%;
  }

  .big-mob-offset-6 {
    margin-left: 50%;
  }

  .big-mob-offset-5 {
    margin-left: 41.66666667%;
  }

  .big-mob-offset-4 {
    margin-left: 33.33333333%;
  }

  .big-mob-offset-3 {
    margin-left: 25%;
  }

  .big-mob-offset-2 {
    margin-left: 16.66666667%;
  }

  .big-mob-offset-1 {
    margin-left: 8.33333333%;
  }

  .big-mob-offset-0 {
    margin-left: 0%;
  }

  .big-mob-height-20 {
    height: 20%;
  }

  .w-big-mob-100 {
    width: 100%;
  }

  .big-mob-reverse {
    flex-direction: row-reverse;
  }

  .big-mob-fixed {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .big-mob-col {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1399px) {

  .des-0 {
    display: none
  }

  .des-1,
  .des-2,
  .des-3,
  .des-4,
  .des-5,
  .des-6,
  .des-7,
  .des-8,
  .des-9,
  .des-10,
  .des-11,
  .des-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .des-1 {
    width: 8.3333%;
  }

  .des-2 {
    width: 16.6667%;
  }

  .des-3 {
    width: 25%;
  }

  .des-4 {
    width: 33.3333%;
  }

  .des-5 {
    width: 41.6667%;
  }

  .des-6 {
    width: 50%;
  }

  .des-7 {
    width: 58.3333%;
  }

  .des-8 {
    width: 66.6667%;
  }

  .des-9 {
    width: 75%;
  }

  .des-10 {
    width: 83.3333%;
  }

  .des-11 {
    width: 91.6667%;
  }

  .des-12 {
    width: 100%;
  }

  .des-offset-12 {
    margin-left: 100%;
  }

  .des-offset-11 {
    margin-left: 91.66666667%;
  }

  .des-offset-10 {
    margin-left: 83.33333333%;
  }

  .des-offset-9 {
    margin-left: 75%;
  }

  .des-offset-8 {
    margin-left: 66.66666667%;
  }

  .des-offset-7 {
    margin-left: 58.33333333%;
  }

  .des-offset-6 {
    margin-left: 50%;
  }

  .des-offset-5 {
    margin-left: 41.66666667%;
  }

  .des-offset-4 {
    margin-left: 33.33333333%;
  }

  .des-offset-3 {
    margin-left: 25%;
  }

  .des-offset-2 {
    margin-left: 16.66666667%;
  }

  .des-offset-1 {
    margin-left: 8.33333333%;
  }

  .des-offset-0 {
    margin-left: 0%;
  }

  .w-des-100 {
    width: 100%;
  }

  .des-pad-sm-tb {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .des-reverse {
    flex-direction: row-reverse;
  }

  .des-fixed {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .des-col {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
  }
}

@media screen and (min-width: 1400px) {

  .big-des-0 {
    display: none
  }

  .big-des-1,
  .big-des-2,
  .big-des-3,
  .big-des-4,
  .big-des-5,
  .big-des-6,
  .big-des-7,
  .big-des-8,
  .big-des-9,
  .big-des-10,
  .big-des-11,
  .big-des-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .big-des-1 {
    width: 8.3333%;
  }

  .big-des-2 {
    width: 16.6667%;
  }

  .big-des-3 {
    width: 25%;
  }

  .big-des-4 {
    width: 33.3333%;
  }

  .big-des-5 {
    width: 41.6667%;
  }

  .big-des-6 {
    width: 50%;
  }

  .big-des-7 {
    width: 58.3333%;
  }

  .big-des-8 {
    width: 66.6667%;
  }

  .big-des-9 {
    width: 75%;
  }

  .big-des-10 {
    width: 83.3333%;
  }

  .big-des-11 {
    width: 91.6667%;
  }

  .big-des-12 {
    width: 100%;
  }

  .big-des-offset-12 {
    margin-left: 100%;
  }

  .big-des-offset-11 {
    margin-left: 91.66666667%;
  }

  .big-des-offset-10 {
    margin-left: 83.33333333%;
  }

  .big-des-offset-9 {
    margin-left: 75%;
  }

  .big-des-offset-8 {
    margin-left: 66.66666667%;
  }

  .big-des-offset-7 {
    margin-left: 58.33333333%;
  }

  .big-des-offset-6 {
    margin-left: 50%;
  }

  .big-des-offset-5 {
    margin-left: 41.66666667%;
  }

  .big-des-offset-4 {
    margin-left: 33.33333333%;
  }

  .big-des-offset-3 {
    margin-left: 25%;
  }

  .big-des-offset-2 {
    margin-left: 16.66666667%;
  }

  .big-des-offset-1 {
    margin-left: 8.33333333%;
  }

  .big-des-offset-0 {
    margin-left: 0%;
  }

  .w-des-100 {
    width: 100%;
  }

  .big-des-pad-sm-tb {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .big-des-reverse {
    flex-direction: row-reverse;
  }

  .big-des-fixed {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .big-des-col {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
  }
}


.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mw-100 {
  max-width: 100%;
}

.mh-100 {
  max-height: 100%;
}

.t-a-c {
  text-align: center;
}

.t-a-l {
  text-align: left;
}

.t-a-r {
  text-align: right;
}

.bold {
  font-weight: bold;
  opacity: 0.9;
}

.pad-lr-none {
  padding-left: 0 !important;
  padding-right: 0 !important;;
}

.pad-lr-sm {
  padding-left: 20px;
  padding-right: 20px;
}

.pad-tb-sm {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pad-tb-md {
  padding-top: 80px;
  padding-bottom: 80px;
}


.scroll {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px; /* for vertical scrollbars */
    height: 5px; /* for horizontal scrollbars */
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.0);
  }

  &::-webkit-scrollbar-thumb {
    background: #8a8a8a;
    border-radius: 5px;
  }
}

.w-h-0 {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: -1;
}

.oneLine {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.oneLineWrap, .olw {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.c-b {
  clear: both;
}

.anim{
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}