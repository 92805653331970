@import "/src/styles/variables.scss";


.lineMain {

  .dataName {
    font-size: 20px;
    font-weight: 500;
  }

  .dataButton {
    margin-left: 10px;
  }
}

.lineBody {
  position: relative;

  .wrapInfo {
    min-height: 300px;
    width: 100%;
    position: absolute;
  }

  .lineData {
    padding: 10px 35px 40px 35px;
    overflow: auto;
    max-width: 100%;

    .block {
      margin: 10px 0;

      .blockHead {
        padding: 20px 0 10px 8px;

        .blockName {
          font-size: 20px;
          color: $color-black-85;
          align-self: center;
        }

        .alignTop {
          align-self: start;
        }

        .blockButton {
          margin-left: 10px;
        }

        .blockHeadItems {
          padding: 0 0 0 10px;
        }
      }

      .blockArrayItemNoData {
        padding: 15px 0;
        opacity: 0.6;
      }

      .blockArrayItemWrap {

        &.thin {
          padding: 0 5px;

          .blockItem {
            padding: 5px 5px 5px 0;
            //min-width: 150px;
          }
        }

        .blockArrayItemColor {
          margin-top: 10px;
          width: 10px;
          border-radius: 3px 0 0 3px;
        }

        .blockArrayItem {
          background: $color-black-02;
          border-radius: 3px;
          border: 1px solid $color-black-15;
          margin-top: 10px;

          .arrayNumber {
            opacity: 0.4;
            //padding: 10px 20px 5px 10px;
            font-size: 1.1em;
          }

          .arrayData {
            //margin-top: -5px;
          }

          &.blockArrayItemHasColor {
            border-radius: 0 3px 3px 0;
          }
        }

      }

    }

  }

  .wrapErrors {
    padding: 0 5px 5px;
    color: $color-red-90;
  }
}

:global(.desk) {
  .main {
    width: 1080px;
    margin: auto;
  }

  .short{
    width: auto;
    margin: unset;
    .lineData, .blockHead {
      padding: 0!important;
    }
  }

  .lineMain {
    padding: 35px 40px 0 32px;
  }

  .lineData {
    padding: 10px 35px 40px 35px;
  }

  .arrayNumber {
    padding: 10px 20px 5px 10px;
  }

  .blockItemTitle {
    padding: 7px 7px 7px 0;
  }

  .blockItem {
    padding: 15px 15px 15px 0;
  }


  .lineControls {
    padding: 10px 50px 40px 0;

    .buttonSave, .buttonCancel {
      min-width: 200px;
      min-height: 40px;
      margin-left: 10px;
    }
  }
}

:global(.mob) {
  .main {
    width: 100%;
    margin: auto;
  }

  .lineMain {
    padding: 15px 10px 0 20px;
  }

  .lineData {
    padding: 10px 10px 25px 10px;
  }

  .arrayNumber {
    padding: 0.4em 0px 5px 3px;
  }

  .blockItemTitle {
    padding: 0.4em 0 0 10px;
  }

  .blockItem {
    padding: 15px 15px 15px 10px;
  }


  .lineControls {
    padding: 10px 0 40px 0;

    .buttonSave, .buttonCancel {
      min-width: 49%;
      min-height: 40px;
      margin-left: 0;
    }
  }
}
