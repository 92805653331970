@import "/src/styles/variables.scss";

.main{
  width: 100vw;
  height: 100vh;
}

.controlPanel{

  .paramWrap, .paramMore{
    padding: 0 0 0 20px;

    .paramName{
      padding: 0 10px;

      .icon{
        opacity: 0.85;
      }
    }

    .paramItem{
      padding: 10px 16px;
      margin: 3px 0 3px 10px;
      height: 39px;
      background: $color-gray;
      border-radius: 3px;
      color: $color-black-75;
      border: 1px solid $color-black-05;

      &.selected, &:active{
        border-bottom: 3px solid $color-highlight-75;
        //background: $color-highlight-75;
      }
    }

    .display{
      position: relative;
      .cursor{
        position: absolute;
        top: 0;
        left: 70%;
        height: 20px;
        opacity: 0.50;
      }
    }
  }

  .paramMore{
    position: absolute;
    top: Calc(100%);
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 10px 0;
    background: $color-white;
    border-bottom: 2px solid $color-gray;
  }

  .menuMore{

    &.selected, &:active{
      color: $color-highlight;
    }
  }
}

.content{
  overflow: auto;
  max-width: 100%;
  position: relative;

  .flyWrap{
    position: absolute;

    .videoWrap{
      width: fit-content;
      height: fit-content;

      position: relative;
      overflow: hidden;

      .video{
        &.fit{
          //max-width: 100vw;
          max-height: Calc(100vh - 48px)
        }
      }
      .cursor{
        position: absolute;
        pointer-events: none;
      }
    }
  }
}

:global(.desk) {


}

:global(.mob) {
  .controlPanel{

    .paramWrap, .paramMore{
      padding: 0 0 0 0px;

      .paramName{
        padding: 0;
      }

      .paramItem{
        //position: relative;
        padding: 10px 10px;
        margin: 5px 0 5px 5px;
        height: 36px;

        .paramMore{
          //top: Calc(100%);

        }
      }
    }

    .paramMore, .paramItem .paramMore{
      top: Calc(100%);
      left: 0;
    }
  }
}
