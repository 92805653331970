@import "/src/styles/variables.scss";

.main {
  text-transform: none;
  font-size: 1em;
  font-weight: normal;
}

.data {

  .empty {
    margin-top: 8px;
    opacity: 0.4;
  }

  .sections {

    .section {
      min-width: 40px;

      &:first-child {
        .sectionItems {
          border-radius: 5px 0 0 5px;
          border-left: 1px solid $color-black-05;
        }
      }

      &:last-child {
        .sectionItems {
          border-radius: 0 5px 5px 0;
        }
      }

      .sectionItems {
        background: $color-black-05;
        border-top: 1px solid $color-black-05;
        border-right: 1px solid $color-black-10;
        border-bottom: 1px solid $color-black-05;
        height: 32px;
        overflow: hidden;

        .sectionItem {
          width: 100%;

          &.notFit{
            //border: 1px solid $color-red;
            //background: $color-yellow!important;

            position: relative;
            z-index: 1;

            &:before{
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              //opacity: .4;
              z-index: -1;
              background: repeating-linear-gradient(
                              45deg, transparent, transparent 10px,  $color-yellow 8px,  $color-yellow 18px)!important;
            }
          }
        }

      }

      .sectionValue {
        font-size: 14px;
        padding: 5px 7px;
        opacity: 0.4;
      }

    }
  }
}