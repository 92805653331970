@import "/src/styles/variables.scss";

.main{
  //padding: 4px 10px 4px 6px !important;
  text-transform: none;
  font-size: 1em;
  font-weight: normal;
  /*&.active{
    background: $color-yellow-30;
    &:hover{
      background: $color-yellow-40;
    }
  }
  &:hover{
    background: $color-black-05;
  }*/
}

.icon{
  opacity: 0.55;
  font-size: 22px;
  &:hover{
    opacity: 1;
  }
}

.text{
  padding: 0 10px 0 0;
  opacity: 0.6;
}

.inputWrap{
  padding-top: 0;
}

.inputAutocomplete{
  min-width: 200px;
}

.inputDropdown{
  min-width: 200px;
}

.title{
  font-size: 1.15em; //16px
  font-weight: 500;
  color: $color-black-80;
}