@import "/src/styles/variables.scss";

.main{
  //padding: 4px 10px 4px 6px !important;
}

.icon{
  opacity: 0.85;
}

/*
.text{
  padding: 0 0 0 5px;
}*/

.forceButton{
  padding: 7px 7px!important;
  min-width: auto;


  :global(.MuiButton-startIcon){
    margin: 0;
  }
}